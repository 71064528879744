<template>
  <form @submit.prevent="resetPassword">
    <div class="card">
      <h2>パスワード設定</h2>
      <p>ご希望のパスワードを入力してください。</p>
      <pv-password label="パスワード" v-model="password" :error="error.password" float full />
      <pv-password label="パスワード再入力" v-model="password2" :error="error.password2" float full />
      <div>
        <pv-button type="submit" label="登録" />
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import http from "axios";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const password = ref("");
    const password2 = ref("");
    const error = ref({ password: "", password2: "" });

    const resetPassword = async () => {
      if (password.value != password2.value) {
        error.value.password2 = "確認用のパスワードが一致しません";
        return;
      } else if (!/^(?=.*?[a-zA-Z])(?=.*?\d).{8,100}$/.test(password.value)) {
        error.value.password = "8文字以上、英字と数字を両方使用して下さい";
        return;
      }
      await http.post('/api/admin/reset_password/'+route.params.token, { password: password.value });

      router.push('/admin/login');
    };

    return { password, password2, error, resetPassword };
  },
}
</script>